const lebjokes = [
	{
		_id: '1',
		joke: "واحد دخل الملعب معه ملعقه ليش؟ يبي يحرك اللعب"
	},
	{
		_id: '2',
		joke: "فيه كابتن طيارة قالوا له وقع قال ماانتهى الدوام"
	},
	{
		_id: '3',
		joke: "فيه واحد تطاقت حرمته مع الشغاله حط كل وحده في بيت"
	},
	{
		_id: '4',
		joke: "نملة تزوج أم أربع وأربعين ما توفق معها ليش؟ ما عرف اي رجل يرفعها"
	},
	{
		_id: '5',
		joke: "واحد حلم انه معزوم وقاعد ياكل في العزيمة يوم قام من النوم واذا هو ماكل نص المخدة"
	},
	{
		_id: '6',
		joke: "واحد حلم انه معزوم وقاعد ياكل في العزيمة يوم قام من النوم واذا هو ماكل نص المخدة"
	}
]

export default lebjokes