const egyjokes = [
	{
		_id: '1',
		joke: "واحد طايحه كل سنونه ألا الأنياب قدم على وظيفه عينوووه خرامه"
	},
	{
		_id: '2',
		joke: "محشش سمع ان الشيطان شاطر راح يدرس معاه"
	},
	{
		_id: '3',
		joke: "محشش مسكوه الشرطة وقالوا له ليش عيونك حمر؟ قال شارب فيمتو وانا منسدح"
	},
	{
		_id: '4',
		joke: "محشش راح للبقالة قال لصاحبها عندك سكر؟ قال راعي البقالة:اي قاله المحشش:الله يشفيك"
	},
	{
		_id: '5',
		joke: "محشش سمع ان الشيطان شاطر راح يدرس معاهاثنين محششين مرو على تفتيش قال السواق لخويه اسكت ولاتقول شي عشان لاتفضحنا . ولما وصلوا عند التفتيش قال الضابط بطاقاتكم..قام السواق وطلع المحفظه . قال له لخويه لا والله العظيم ماتدفع اليوم على حسابي"
	},
	{
		_id: '6',
		joke: "محشش مسكوه الشرطة وقالوا له ليش عيونك حمر؟ قال شارب فيمتو وانا منسدحمحشش وقف عند نقطه تفتيش قاله بلله عبي لي بنزين بعشره"
	}
]

export default egyjokes