const jokeslisitng = [
	{
		id: '1',
		link: 'Lebanon',
		name: 'نكت  لبنانية',
		icon: './img/leb/icon.svg',
		background: './img/leb/bg.jpg',
		jokelist: [
			{
				j_id: '1',
				joke: "* واحد دخل الملعب معه ملعقه ليش؟ يبي يحرك اللعب"
			},
			{
				j_id: '2',
				joke: "* فيه كابتن طيارة قالوا له وقع قال ماانتهى الدوام"
			},
			{
				j_id: '3',
				joke: "* فيه واحد تطاقت حرمته مع الشغاله حط كل وحده في بيت"
			},
			{
				j_id: '4',
				joke: "* نملة تزوج أم أربع وأربعين ما توفق معها ليش؟ ما عرف اي رجل يرفعها"
			},
			{
				j_id: '5',
				joke: "* واحد حلم انه معزوم وقاعد ياكل في العزيمة يوم قام من النوم واذا هو ماكل نص المخدة"
			},
			{
				j_id: '6',
				joke: "* واحد حلم انه معزوم وقاعد ياكل في العزيمة يوم قام من النوم واذا هو ماكل نص المخدة"
			}
		]
	},

	{
		id: '2',
		link: 'Egypt',
		name: 'نكت  مصرية',
		icon: './img/egy/icon.svg',
		background: './img/egy/bg.jpg',
		jokelist: [
			{
				j_id: '1',
				joke: "واحد طايحه كل سنونه ألا الأنياب قدم على وظيفه عينوووه خرامه"
			},
			{
				j_id: '2',
				joke: "محشش سمع ان الشيطان شاطر راح يدرس معاه"
			},
			{
				j_id: '3',
				joke: "محشش مسكوه الشرطة وقالوا له ليش عيونك حمر؟ قال شارب فيمتو وانا منسدح"
			},
			{
				j_id: '4',
				joke: "محشش راح للبقالة قال لصاحبها عندك سكر؟ قال راعي البقالة:اي قاله المحشش:الله يشفيك"
			},
			{
				j_id: '5',
				joke: "محشش سمع ان الشيطان شاطر راح يدرس معاهاثنين محششين مرو على تفتيش قال السواق لخويه اسكت ولاتقول شي عشان لاتفضحنا . ولما وصلوا عند التفتيش قال الضابط بطاقاتكم..قام السواق وطلع المحفظه . قال له لخويه لا والله العظيم ماتدفع اليوم على حسابي"
			},
			{
				j_id: '6',
				joke: "محشش مسكوه الشرطة وقالوا له ليش عيونك حمر؟ قال شارب فيمتو وانا منسدحمحشش وقف عند نقطه تفتيش قاله بلله عبي لي بنزين بعشره"
			}
		]
	},

	{
		id: '3',
		link: 'KSA',
		name: 'نكت  سعودية',
		icon: './img/ksa/icon.svg',
		background: './img/ksa/bg.jpg',
		jokelist: [
			{
				j_id: '1',
				joke: "واحد طايحه كل سنونه ألا الأنياب قدم على وظيفه عينوووه خرامهواحد قروي ركب مع تكسي، ركب زوجته قدام علشان السواق ما يناظرها بالمراية"
			},
			{
				j_id: '2',
				joke: "لاعب منحوس سجل هدف ، لما عادوه بالحركة البطيئه ، طلعت الكوره كورنر"
			},
			{
				j_id: '3',
				joke: "واحــد حـــب ... طــحــنــوه"
			},
			{
				j_id: '4',
				joke: "ديك مزكم ليه ... جامع دجاجه مثلجه"
			},
			{
				j_id: '5',
				joke: "واحد جاء الى جده لقيه يطلع في الروح ... لحقه بسكين"
			},
			{
				j_id: '6',
				joke: "فيه واحد محشش كان يسوق السيارة وقف عند نقطة تفتيش قال له العسكري : لو سمحت اوراق السيارة ... قال المحشش : أي حشيش اللي تتكلم عنه"
			}
		]
	},

	{
		id: '4',
		link: 'Iraq',
		name: 'نكت  عراقية',
		icon: './img/irq/icon.svg',
		background: './img/irq/bg.jpg',
		jokelist: [
			{
				j_id: '1',
				joke: "مصري مطبب راسه بالبطل، گالوله شنو هاي شدتسوي گال: رأبتي سداده"
			},
			{
				j_id: '2',
				joke: "واحد محشش راح لابو صيدلية كلة عندك شريط فلو اوت؟؟؟كله ايكله حطة خلي نسمعة"
			},
			{
				j_id: '3',
				joke: "فد يوم باجتماع بين مسؤولين من العراق والصين والأردن شافوا رئيس الوفد الصيني يأشر بأصبعه للسمه رئيس الوفد الأردني سأل :هذا شبيه كلوله هذا ديتصل عبر الاقمار الصناعيه ورة شوية باوع على عدنان الدليمي وشاف راسه يهتز كال هذا اشبيه؟كالوله هذا يمكن اجته رساله"
			},
			{
				j_id: '4',
				joke: "اعمى گام يشوف صار بيه شلل من الفرحه"
			},
			{
				j_id: '5',
				joke: "واحد متعاجز حيل مرته دگت الباب گاللهه: انتي طالق"
			},
			{
				j_id: '6',
				joke: "خبيث فتح مدرسه للخباثه لما كمل عدد الطلاب سدهه"
			}
		]
	},
]

export default jokeslisitng