const iraqjokes = [
	{
		_id: '1',
		joke: "مصري مطبب راسه بالبطل، گالوله شنو هاي شدتسوي گال: رأبتي سداده"
	},
	{
		_id: '2',
		joke: "واحد محشش راح لابو صيدلية كلة عندك شريط فلو اوت؟؟؟كله ايكله حطة خلي نسمعة"
	},
	{
		_id: '3',
		joke: "فد يوم باجتماع بين مسؤولين من العراق والصين والأردن شافوا رئيس الوفد الصيني يأشر بأصبعه للسمه رئيس الوفد الأردني سأل :هذا شبيه كلوله هذا ديتصل عبر الاقمار الصناعيه ورة شوية باوع على عدنان الدليمي وشاف راسه يهتز كال هذا اشبيه؟كالوله هذا يمكن اجته رساله"
	},
	{
		j_id: '4',
		joke: "اعمى گام يشوف صار بيه شلل من الفرحه"
	},
	{
		_id: '5',
		joke: "واحد متعاجز حيل مرته دگت الباب گاللهه: انتي طالق"
	},
	{
		_id: '6',
		joke: "خبيث فتح مدرسه للخباثه لما كمل عدد الطلاب سدهه"
	}
]

export default iraqjokes