const ksajokes = [
	{
		_id: '1',
		joke: "واحد طايحه كل سنونه ألا الأنياب قدم على وظيفه عينوووه خرامهواحد قروي ركب مع تكسي، ركب زوجته قدام علشان السواق ما يناظرها بالمراية"
	},
	{
		_id: '2',
		joke: "لاعب منحوس سجل هدف ، لما عادوه بالحركة البطيئه ، طلعت الكوره كورنر"
	},
	{
		_id: '3',
		joke: "واحــد حـــب ... طــحــنــوه"
	},
	{
		_id: '4',
		joke: "ديك مزكم ليه ... جامع دجاجه مثلجه"
	},
	{
		_id: '5',
		joke: "واحد جاء الى جده لقيه يطلع في الروح ... لحقه بسكين"
	},
	{
		_id: '6',
		joke: "فيه واحد محشش كان يسوق السيارة وقف عند نقطة تفتيش قال له العسكري : لو سمحت اوراق السيارة ... قال المحشش : أي حشيش اللي تتكلم عنه"
	}
]

export default ksajokes